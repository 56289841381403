/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


body,
html,
select,
input,
button,
ion-button,
ion-select,
ion-content {
  text-transform: uppercase !important;
}

ion-content {
  --background: transparent;
  background: url("./assets/images/bg.png") center top no-repeat rgb(30 54 139);
  background-size: cover;
  --color: #fff;
  color: #fff;
  --padding-bottom: 50px !important;
}

.list-md,
.list-ios {
  --background: transparent;
  background: transparent;
}

ion-button {
  border-radius: 10px;
  --border-radius: 10px;
}

ion-footer ion-toolbar {
  color: #fff;
  --background-color: #081954;
  --ion-toolbar-background: #081954;
  text-align: center;
  padding: 10px 5px;
}

.toastdiv {
  --background: #081954;
  --color: #fff;
  --border-style: solid;
  --border-width: 2px;
  --border-color: #1d3383;
  --button-color: #fff;
  --start: 5%;
  --end: 5%;

}

.toastdiv .toast-wrapper {}

.ion-margin-top {
  margin-top: 16px !important;
}

.max300 {
  width: 300px;
  display: inline-block;
  text-align: left;
}

.bgGradiente {
  --background: #d8d8d8;
  --background: linear-gradient(to bottom, #d8d8d8 0%, #ffffff 64%);
}

.transparent {
  background: transparent !important;
  --background: transparent !important;
}

.bgRed {
  background: #f8575a;
  --background: linear-gradient(to right,
      rgba(255, 64, 64, 1) 0%,
      rgba(255, 64, 64, 1) 1%,
      rgba(30, 54, 139, 0.51) 100%);
  color: #fff;
  text-align: center;
  font-weight: 800;
  height: 34px;
  line-height: 30px;
  --min-height: 30px;
}

.header ion-title {
  text-align: center;
}

.header .botoa {
  box-shadow: 4px -1px 5px -6px #000 !important;
  border-radius: 0;
  background: transparent;
  color: #1e368b;
  --color: #1e368b;
  flex-basis: 100%;
  text-align: center;
  --padding-start: 2px;
  --padding-end: 4px;
  text-align: center;
}

.header .botoa.right {
  box-shadow: -4px -1px 5px -6px #000 !important;
}

.header .botoa.solo {
  width: 48px !important;
  height: 48px !important;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
}

.header .botoa ion-icon {
  font-size: 16px;
  margin: 0 10px;
}

.header .botoa small {
  font-size: 9px;
}

.header .logoheader img {
  max-height: 35px;
}

.header .quoteBtns {
  padding: 0;
  margin: 0;
  --padding: 0;
  --margin: 0;
  --min-height: 35px;
}

.header .quoteBtns ion-buttons {
  width: 100%;
}

.header .quoteBtns .botoa ion-icon {
  margin: 0 3px 0 0;
}

.header .quoteBtns .botoa:last-child {
  box-shadow: none !important;
}

.botoes {
  display: inline-flex;
}

.botoes span {
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  margin-right: 5px;
  border-left: 1px solid #dcd1d1;
}

.fotos .apaga {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
  --border-radius: 50% !important;
  line-height: 30px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 6px;
  --padding-end: 6px;
}

.fotos ion-img {
  border: solid 2px #cccccc;
  border-radius: 8px;
  overflow: hidden;
}

form ion-input,
form ion-select,
form ion-textarea {
  background: #f0f0f0 !important;
}

.searchBox {
  background: #f84447;
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 300px;
}

.searchBox ion-row {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.searchBox ion-icon {
  font-size: 26px;
}

.searchBox ion-input {
  color: #fff !important;
  background: #f84447 !important;
}

.topo {
  text-align: center;
}

.topo .logoimg {
  position: absolute;
  width: 100%;
  padding: 10px 0 0 0;
  z-index: 2;
}

.topo img {
  width: 100%;
}

.topo .footer {
  position: relative;
  margin-top: -30px;
}

.f12 {
  font-size: 12px;
}

.f14 {
  font-size: 14px;
}

.error-message {
  text-align: left;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #ff8dca;
  --line-height: 14px;
  line-height: 14px;
  padding: 10px 0 5px;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

a {
  text-decoration: none;
  color: #fff;
}

ion-input,
ion-select,
ion-textarea {
  --background: #fff !important;
  background: #fff !important;
  border: none !important;
  border-radius: 3px !important;
  --padding-start: 10px !important;
  color: #02134f !important;
}

.search {
  --background: #eeeaea;
  --border: none;
  --box-shadow: none;
  margin: 0 10px 10px;
  max-width: 320px;
}

ion-title {
  text-transform: uppercase;
}

ion-button {
  height: 40px !important;
  margin: 0 !important;
  --border-radius: 4px;
}

ion-fab,
ion-button,
ion-fab-button,
.ion-fab-button {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.modal-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  --width: 100%;
  --height: 100%;
  z-index: 99;
}

.modal-fullscreen ion-backdrop {
  visibility: visible;
  opacity: 0.75;
  z-index: 0;
}

.top-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.hr {
  height: 3px;
  border: solid 1px #f84143;
  background: #f84142;
  margin: -1px auto 0;
  width: 80px;
  border-radius: 5px;
  padding: 0;
}

.interno {
  margin: 10px;
  background: #cccccc25;
  box-shadow: none;
  border: solid 1px #ddd;
}

.interno.close {
  height: 40px;
}

.d-inline {
  display: inline-block;
}

.d-block {
  display: block;
}

.ion-margin-horizontal {
  margin: 5px;
}


.interno ion-card-header {
  --background: #d8d8d8;

  --background: linear-gradient(to bottom, #ffffff 0%, #d8d8d8 64%);
  margin: 0;
  padding: 5px;
}

.interno ion-card-header ion-card-subtitle {
  color: #1e368b;
  --color: #1e368b;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interno ion-card-content {
  margin: 0;
  padding: 5px;
}

.interno ion-card-content .modulos {
  background: #fff;
  padding: 0;
  margin: 0 0 10px 0;
  border: solid #ccc 1px;
  border-radius: 8px;
}

.interno ion-card-content .modulos .title {
  color: #1e368b;
  --color: #1e368b;
  padding: 3px 5px;
  border-bottom: solid 1px #ccc;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titulo {
  margin: 18px;
  font-size: 20px;
  color: #1e368b;
  font-weight: 700;
  text-align: center;
}

.interno ion-card-content .modulos .mg5 {
  margin: 10px;
}

.interno ion-card-content .modulos .dataItem {
  font-size: 14px;
}

.interno ion-card-content .modulos .dataItem ion-input,
.interno ion-card-content .modulos .dataItem ion-textarea,
.interno ion-card-content .note ion-textarea {
  background: rgb(204 204 204 / 0.4) !important;
  border: solid 1px rgb(204 204 204 / 0.4) !important;
  --padding-top: 4px;
  --padding-bottom: 4px;
}

.interno ion-card-content .modulos .dataItem ion-input.disabled {
  --background: #fff !important;
  background: #fff !important;
  border: solid 1px #ccc !important;
}

ion-footer .button-native span.button-inner {
  display: contents !important;
  width: 100%;
  height: 100%;
  z-index: 1;
}

ion-item {
  margin-bottom: 10px;
  border-radius: 10px;
}

ion-item h3,
.phone {
  color: #1E368B;
  font-weight: 800;
  text-transform: capitalize;
}

ion-item .address {
  color: #666666
}

ion-note {
  margin-left: 0;
  margin-right: 0;
}

ion-note ion-button {
  box-shadow: 0px 1px 4px #888888 !important;
  --box-shadow: 0px 1px 4px #888888 !important;
  height: 30px !important;
}

.search {
  margin: 0;
}